import React from 'react'
import {Link} from 'gatsby'
import {removeEmoji} from '../utils'

const LinkMeta = (
  {
    item,
    lang,
    toggle
  }
) => {
  const {
    __typename,
    icon,
    link,
    name,
    slug,
    styleOption,
    openInNewTab
  } = item

  const getLinkClass = (style) => {
    switch (style) {
      case 'Primary Button':
        return 'button button__primary'
      case 'Secondary Button':
        return 'button button__secondary'
      case 'Icon Button':
        return 'button button__icon'
      case 'Standard Link':
        return 'link'
      default:
        return 'link'
    }
  }

  const getIcon = (file) => {
    if (file) {
      return (
        <img src={file.file.url} alt={file.title}/>
      )
    }
  }

  const getIconClass = (file) => {
    if (file) {
      return 'link--icon'
    } else {
      return ''
    }
  }

  switch (__typename) {
    case 'ContentfulPageLandingpage':
      return <Link
        className="link"
        to={`/${lang ? lang : item.node_locale}/${slug}`}
        onClick={toggle}
      >
        {removeEmoji(name)}
      </Link>
    case 'InternalLink':
      return <Link
        className={`${getLinkClass(styleOption)} ${getIconClass(icon)}`}
        to={`/${lang ? lang : item.node_locale}/${slug}`}
        onClick={toggle}
        title={removeEmoji(name)}
      >
        <span className="a-icon">{getIcon(icon)}</span>
        <span className="button__text">{removeEmoji(name)}</span>
      </Link>
    case 'ContentfulMetaLink':
      return (
        <a
          href={link}
          target={openInNewTab ? "_blank" : "_self"}
          className={`${getLinkClass(styleOption)} ${getIconClass(icon)}`}
          onClick={toggle}
          rel="noreferrer"
          title={removeEmoji(name)}
        >
          <span className="a-icon">{getIcon(icon)}</span>
          <span className="button__text">{removeEmoji(name)}</span>
        </a>
      )
    default:
      return <span>TBD</span>
  }
}

export default LinkMeta
