import React from 'react'

// above the fold content
import '../sass/styles.scss'
import '../sass/modules/components/_layout.scss'
import '../sass/modules/atoms/_fonts.scss'
import '../sass/modules/atoms/_typography.scss'
import '../components/header/_header.scss'
import '../components/navigation/_navigation.scss'
import '../sass/modules/components/_links.scss'

import Header from './header/header'
import Footer from './footer/footer'

const Layout = ({
  header,
  footer,
  lang,
  location,
  children
}) => {
  return (
    <main className="main">
      <Header lang={lang} header={header}/>
      { children }
      <Footer lang={lang} location={location} footer={footer}/>
    </main>
  )
}

export default Layout
