import React from 'react'
import { Helmet } from 'react-helmet'

const HelmetWrapper = (props) => {
  const {
    content,
    activeLang
  } = props

  const {
    metaCanonical,
    metaTitle,
    metaDescription,
    metaFollow,
    metaIndex,
    metaKeywords,
    ogDescription,
    ogImg,
    ogTitle,
    ogType,
    ogUrl
  } = content

  return (
    <Helmet
      htmlAttributes={{ lang: activeLang}}
      title={metaTitle}
    >

      { metaCanonical &&
        <link rel="canonical" href={metaCanonical} />
      }

      { metaDescription &&
        <meta name="description" content={metaDescription} />
      }

      { metaKeywords &&
        <meta name="keywords" content={metaKeywords} />
      }

      <meta name="robots" content={`${metaIndex},${metaFollow}`} />

      { ogTitle &&
        <meta property="og:title" content={ogTitle} />
      }

      { ogDescription &&
        <meta property="og:description" content={ogDescription} />
      }

      { ogType &&
        <meta property="og:type" content={ogType} />
      }

      { ogUrl &&
        <meta property="og:url" content={ogUrl} />
      }

      { ogImg &&
        <meta property="og:image" content={`https:${ogImg}?fm=png&w=1200&h=1200&crop=fill`} />
      }

    </Helmet>
  )
}

export default HelmetWrapper
