import React from 'react'
import {BLOCKS, INLINES, MARKS} from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import LinkMeta from "../link/link";

const Bold = ({ children }) => <span>{children}</span>

const AnimationWrapper = ({ children }) => {
  return (
    <div className="gs-wrapper js-gs-wrapper">
      {children}
    </div>
  )
}

let headlineAnimation = false

const options = {
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{ text }</Bold>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (headlineAnimation) {
        return (
          <AnimationWrapper>
            <h2 className='headline headline--h1 js-gs-animation'>
              {children}
            </h2>
          </AnimationWrapper>
        )
      }

      return <h2 className='headline headline--h1'>{children}</h2>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      if (headlineAnimation) {
        return (
          <AnimationWrapper>
            <h2 className='headline headline--h1 js-gs-animation'>
              {children}
            </h2>
          </AnimationWrapper>
        )
      }

      return <h2 className='headline headline--h1'>{children}</h2>
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const entry = node.data.target
      const type = entry.__typename
      switch (type) {
        case 'ContentfulMetaLink':
          return <LinkMeta item={entry} />
        default:
          return null
      }
    },
  }
}

const Headline = ({ content, animation }) => {
  headlineAnimation = animation

  return renderRichText(content, options)
}

export default Headline
