import React from 'react'

import './_htmlWrapper.scss'

const HTMLWrapper = ({ code }) => {
  const { htmlCode } = code.htmlCode

  if (process.env.GATSBY_HOST !== 'cdn'){
    return null
  }

  return (
    <div className="html-wrapper cell" dangerouslySetInnerHTML={{ __html: htmlCode }} />
  )
}

export default HTMLWrapper
