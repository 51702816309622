import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { isMobile } from '../utils'

import './_sectionNavigation.scss'

const SectionNav = ({
  items
}) => {
  gsap.registerPlugin(ScrollTrigger)

  const handleJump = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const targetID = e.currentTarget.getAttribute('href')
    const target = document.getElementById(targetID.replace('#', ''))
    const offSet = isMobile() ? 0 : -90

    gsap.to(window, { duration: .250, scrollTo: { y: target, offsetY: offSet }, ease: "none" })
  }

  useEffect(() => {
    let currentColor,
      bgColor = '#ffffff',
      txtColor = '#000000',
      boxColor = 'transparent'

    items.forEach((section) => {
      const sectionId = `#${CSS.escape(section.id)}`,
            sectionEl = document.querySelector('[id="' + section.id + '"]'),
            sectionClasses = sectionEl.querySelector('.section__wrapper').classList

      if (sectionClasses.contains('section__wrapper--dark')) {
        bgColor = '#000000'
        txtColor = '#ffffff'
        boxColor = 'transparent'
      } else if (sectionClasses.contains('section__wrapper--lite')) {
        bgColor = '#ffffff'
        txtColor = '#000000'
        boxColor = '#ffffff'
      }

      if (bgColor !== currentColor){
        currentColor = bgColor

        const options = {
          id: sectionId,
          trigger: sectionId,
          scrub: true,
          toggleActions: 'play none play none',
        }

        gsap.to('.section-nav .section-nav__item', {
          scrollTrigger: options,
          color: txtColor
        })

        gsap.to('.section-nav .section-nav__text', {
          scrollTrigger: options,
          backgroundColor: boxColor
        })

        gsap.to('.section-nav .section-nav__dot', {
          scrollTrigger: options,
          borderColor: txtColor,
          boxShadow: '0px 0px 5px 2px ' + boxColor,
        })
      }

      const highlightOptions = {
        id: sectionId,
        trigger: sectionId,
        start: 'top center',
        end: 'bottom center',
        toggleActions: 'play reverse play reverse',
      }

      gsap.to('a[href="#' + section.id + '"] .section-nav__dot', {
        scrollTrigger: highlightOptions,
        width: 12,
        height: 12,
        marginBottom: -3,
        marginRight: -3,
      })
    })

  })

  return (
    <div className="section-nav">
      {
        items.map((navItem, index)=>
          <a
            key={index}
            className="section-nav__item"
            href={`#${navItem.id}`}
            title={navItem.name}
            onClick={handleJump}
          >
            <span className="section-nav__text">{ navItem.name }</span>
            <span className="section-nav__dot"></span>
          </a>
        )
      }
    </div>
  )
}

export default SectionNav
