import React from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Headline from '../headline/headline'
import LinkMeta from '../link/link'

import './_richtext.scss'

const options = {
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (children.length === 1 && children[0][1] === '') {
        return <br />
      }

      return <p className="richtext richtext__p">{children}</p>
    },
    [BLOCKS.HEADING_1]: (node, children) => <h1 className="richtext richtext__h1">{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 className="richtext richtext__h1">{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 className="richtext richtext__h1">{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4 className="richtext richtext__h1">{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5 className="richtext richtext__h1">{children}</h5>,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      return <Headline content={node.data.target.content} animation={node.data.target.animation}/>
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node.data.target

      return <img className="richtext richtext__media" src={file.url} alt={title} loading="lazy"/>
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const entry = node.data.target
      const type = entry.__typename
      switch (type) {
        case 'ContentfulMetaLink':
          if (entry.internalLink) {
            const internalLinkEntry = {
              __typename: 'InternalLink',
              styleOption: entry.styleOption,
              openInNewTab: entry.openInNewTab,
              slug: entry.internalLink.slug,
              name: entry.name,
              node_locale: entry.internalLink.node_locale,
              sys: entry.sys,
              icon: entry.icon
            }
            return <LinkMeta item={internalLinkEntry} />
          }
          return <LinkMeta item={entry} />
        default:
          return null
      }
    },
    [INLINES.ENTRY_HYPERLINK]: (node) => {
      const entry = node.data.target
      const type = entry.__typename
      switch (type) {
        case 'ContentfulMetaLink':
          return <LinkMeta item={entry} />
        default:
          return null
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const { file } = node.data.target

      return (
        <a
          className="link link--download"
          href={file.url}
          target="_blank"
          rel="noreferrer"
        >
          <span className="a-icon">
            <svg className="icon">
              <use xlinkHref="/static/svg/svg-sprite.svg#mp-download"></use>
            </svg>
          </span>
          <span className="button__text">
            { children }
          </span>
        </a>
      )
    }
  }
}

const RichText = ({ content }) => {
  return renderRichText(content, options)
}

export default RichText
