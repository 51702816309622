import React from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { getLangNameFromCode } from 'language-name-map'

const isMobile = () => {
  return window.innerWidth < 951
}

const getMediaType = (str) => {
  return str.split('.').pop()
}

const getLanguageName = (lang) => {
  const nativeName = getLangNameFromCode(lang).native

  return (
    <span>{ nativeName }</span>
  )
}

const getCellClass = (styleOption) => {
  switch (styleOption) {
    case 'Full width':
      return 'cell full-width'
    case '1/1':
      return 'cell'
    case '1/2':
      return 'cell large-6'
    case '1/3':
      return 'cell large-4'
    case '1/4':
      return 'cell large-3'
    case '2/3':
      return 'cell large-8'
    default:
      return 'cell'
  }
}

const getBackgroundClass = (section) => {
  let returnClass = ''
  const isVideo = getMediaType(section.backgroundImage.file.url) === 'mp4'

  switch(section.backgroundPosition.toLowerCase()){
    case 'left':
      returnClass = 'left'
      break
    case 'center':
      returnClass =  'center'
      break
    case 'right':
      returnClass = 'right'
      break
    default:
      returnClass = 'center'
  }

  if (isVideo) {
    returnClass += ' section__bg--video'
  }

  return returnClass
}

const getSectionAlignment = (sectionAlignment) => {
  switch(sectionAlignment.toLowerCase()){
    case 'left':
      return 'align-left'
    case 'center':
      return 'align-middle'
    case 'right':
      return 'align-right'
    default:
      return 'align-left'
  }
}

const mapAnimations = () => {
  const animatedEl = document.querySelectorAll('.js-gs-wrapper')

  gsap.registerPlugin(ScrollTrigger)

  if (animatedEl){
    const animateFrom = (elem) => {
      gsap.fromTo(elem, {
          y: -100,
          autoAlpha: 0,
          opacity: 0,
        },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          autoAlpha: 1,
          delay: .5,
          ease: "expo",
          overwrite: "auto"
        });
    }

    const hideAnimation = (elem) => {
      gsap.set(elem, { autoAlpha: 0 });
    }

    const showAnimation = (elem) => {
      gsap.set(elem, { autoAlpha: 1 });
    }

    gsap.utils.toArray('.js-gs-wrapper').forEach(wrapper => {
      hideAnimation(wrapper)

      ScrollTrigger.create({
        fastScrollEnd: true,
        markers: false,
        trigger: wrapper,
        start: 'top bottom',
        toggleActions: 'play reset play reset',
        onEnter: () => {
          const fadeEl = wrapper.querySelector('.js-gs-animation')

          showAnimation(wrapper)
          animateFrom(fadeEl)
        },
        onEnterBack: () => {
          showAnimation(wrapper)
        },
        onLeave: () => {
          hideAnimation(wrapper)
          ScrollTrigger.refresh()
        },
        onLeaveBack: () => {
          wrapper.style.opacity = 0
          ScrollTrigger.refresh()
        },
      })
    })
  }

}

const removeEmoji = (str) => {
  if (str !== null) return str.replace(new RegExp('[\u00FF-\uFFFF]+','g'), '')
}

const getBrowserLanguage = (langProps) => {
  let inLanguages = false

  if (typeof navigator === 'undefined') {
    return false
  }

  const browserLang = navigator && navigator.language && navigator.language.split("-")[0]

  langProps.lang.forEach( currentLang => {
    if (currentLang.node.code === browserLang){
      inLanguages = true
    }
  })

  if (browserLang !== langProps.defaultLang && inLanguages) {
    return browserLang
  }

  return false
}

export {
  isMobile,
  getBackgroundClass,
  getBrowserLanguage,
  getCellClass,
  getLanguageName,
  getMediaType,
  getSectionAlignment,
  mapAnimations,
  removeEmoji
}
