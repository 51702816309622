import React, {useEffect, useState} from 'react'
import { Link } from 'gatsby'
import { getLanguageName } from '../utils'
import LinkMeta from '../link/link'

import '../../sass/modules/atoms/_icons.scss'
import './_footer.scss'

const Footer = ({
  footer,
  lang,
  location
}) => {
  const {
    leftSection,
    centerSection,
    rightSection
  } = footer
  const [langMenuOpen, setLangMenuOpen] = useState(false);
  let privateSettingsStr

  switch (lang.activeLang){
    case 'en':
      privateSettingsStr = 'Privacy Settings'
      break
    default:
      privateSettingsStr = 'Datenschutzeinstellungen'
      break
  }

  const toggleLang = () => {
    setLangMenuOpen(!langMenuOpen);
  }

  useEffect(() => {
    if (process.env.GATSBY_HOST === 'cdn'){
      const privacy = document.createElement('a')
      privacy.setAttribute('href', '#')
      privacy.classList.add('link')
      privacy.setAttribute('onClick', 'UC_UI.showSecondLayer()')
      privacy.innerText = privateSettingsStr

      privacy.addEventListener('click', (e) => {
        e.preventDefault()
      })

      document.querySelector('.footer__cell').appendChild(privacy)
    }
  }, [privateSettingsStr])

  if (!footer) {
    return null
  }

  return (
    <footer className="footer">
      <div className="grid-container">
        <div className="grid-x grid-padding-x">

          <div className="footer__cell cell small-12 large-5 text-center large-text-left">
            { leftSection && leftSection.map((item) =>
              <LinkMeta
                lang={item.node_locale}
                key={item.id}
                item={item}
              />
            )}
          </div>

          <div className="footer__cell cell small-12 large-4 text-center">
            { centerSection && centerSection.map((item) =>
              <LinkMeta
                lang={item.node_locale}
                key={item.id}
                item={item}
              />
            )}

            <div className="footer__language">
              <button
                className={`footer__language-toggle${langMenuOpen ? ' active' : ''}`}
                onClick={toggleLang}
              >
                <svg className="icon icon--small">
                  <use xlinkHref="/static/svg/svg-sprite.svg#mp-lang-earth"></use>
                </svg>
                { getLanguageName(lang.activeLang) }
              </button>
              <div className="footer__language-wrapper">
                { lang.lang.map((item) => {
                  let url =  location.pathname.replace('/'+ lang.activeLang, '/' + item.node.code)

                  if (item.node.default && lang.isHomepage) {
                    url = '/'
                  }

                  if (location.pathname === '/' && item.node.code === lang.defaultLang) {
                    url = '/'
                  }

                  if (location.pathname === '/' && item.node.code !== lang.defaultLang) {
                    url = '/' + item.node.code
                  }

                  return (
                    <Link
                      key={item.node.name}
                      to={url}
                    >
                      { getLanguageName(item.node.code) }
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="footer__cell cell small-12 large-3 text-center large-text-right">
            { rightSection && rightSection.map((item) =>
              <LinkMeta
                lang={item.node_locale}
                key={item.id}
                item={item}
              />
            )}
          </div>

        </div>
      </div>

    </footer>
  )
}

export default Footer
