import React, { Fragment, useState } from 'react'
import { isMobile } from '../utils'
import LinkMeta from '../link/link'

// sass in layout.js - above the fold

const Navigation = ({
  contact,
  items,
  lang
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigationActivated = items?.length > 0

  const useToggle = () => {
    if (isMobile) {
      setMenuOpen(!menuOpen);
      document.body.classList.toggle('no-scroll');
    }
  }

  return (
    <nav className={`navigation${menuOpen ? ' navigation--show' : ''}`}>
      { navigationActivated &&
        <button aria-label="Menu" onClick={useToggle} className="navigation__trigger">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </button>
      }
      <div className="navigation__menu">
        { items?.map((navItem, index) => {
          if (items.length === index + 1 && navItem.__typename === 'ContentfulMetaLink'){
            return (
              <Fragment key={navItem.id}>
                <div className="navigation__spacer hide-for-large"></div>
                <LinkMeta
                  lang={lang.activeLang}
                  item={navItem}
                  toggle={useToggle}
                />
              </Fragment>
            )
          } else if (items.length === index + 1 && navItem.__typename !== 'ContentfulMetaLink'){
            return (
              <Fragment key={navItem.id}>
                <LinkMeta
                  lang={lang.activeLang}
                  item={navItem}
                  toggle={useToggle}
                />
                <div className="navigation__spacer hide-for-large"></div>
              </Fragment>
            )
          }

          return <LinkMeta
            lang={lang.activeLang}
            key={navItem.id}
            item={navItem}
            toggle={useToggle}
          />
        })}

        { contact &&
          <div className="navigation__contact hide-for-large">
            { contact.map((link) => (
             <LinkMeta
               lang={lang.activeLang}
               key={link.id}
               item={link}
             />
            ))}
          </div>
        }

      </div>
    </nav>
  )
}

export default Navigation
