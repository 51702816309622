import React from 'react'
import { Link } from 'gatsby'

// sass in layout.js - above the fold

import Navigation from '../navigation/navigation'

const Header = ({
  header,
  lang
}) => {
  const { logo, navigation, contactFieldMobile } = header
  const isDefaultLanguage = lang?.activeLang === lang?.defaultLang;
  const homepageSlug = isDefaultLanguage ? `/` : `/${lang?.activeLang}`;

  return (
    <header className="header">

      <div className="grid-container">
        <div className="grid-x grid-padding-x align-middle">

          <div className="cell small-8 large-4">
            <Link to={homepageSlug} aria-label={logo.title}>
              <img
                className="header__logo"
                src={logo.file.url}
                alt={logo.title}
                aria-label={logo.title}
              />
            </Link>
          </div>

          <div className="cell small-4 large-8 text-right">
            <Navigation lang={lang} items={navigation} contact={contactFieldMobile}/>
          </div>

        </div>
      </div>
    </header>
  )
}

export default Header
