import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import './_media.scss'

const Media = ({ media, mediaResponsive }) => {
  let hasResponsiveMedia = false

  if (mediaResponsive?.gatsbyImageData || mediaResponsive?.file) {
    hasResponsiveMedia = true
  }

  return (
    <span className="media">
      { media.gatsbyImageData ?
        <GatsbyImage
          image={media.gatsbyImageData}
          alt={media.title}
          objectFit={'cover'}
          layout="fullWidth"
          sizes={`${!hasResponsiveMedia ? '(max-width: 480px) 480px, (max-width: 960px) 960px, ' : ''}(max-width: 1200px) 1200px, (max-width: 1920px) 1920px`}
        />
        :
        <img
          className={`media__img${hasResponsiveMedia ? ' show-for-large' : ''}`}
          src={media.file.url}
          alt={media.title}
          loading="lazy"
        />
      }

      {(hasResponsiveMedia && mediaResponsive.gatsbyImageData) &&
        <GatsbyImage
          className="show-for-small-only"
          image={mediaResponsive.gatsbyImageData}
          alt={mediaResponsive.title}
          objectFit={'cover'}
          layout="fullWidth"
          sizes={'(max-width: 480px) 480px, (max-width: 960px) 960px'}
        />
      }

      {(hasResponsiveMedia && mediaResponsive.file.url) &&
        <img
          className="media__img hide-for-large"
          src={`${mediaResponsive.file.url}?w=960`}
          alt={mediaResponsive.title}
          loading="lazy"
        />
      }
    </span>
  )
}

export default Media
